import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faGithub, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import "@splidejs/react-splide/css";
import Card from './components/Card';
import { useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { socket } from './socket';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { GlobalDelayCheck } from "./components/DelayState.jsx";


const Star = () => {
  const myMesh = useRef();
  useFrame(() => {
    myMesh.current.position.z += 0.04;
    if (myMesh.current.position.z > 10) myMesh.current.position.z = -50;
  });

  return (
    <mesh ref={myMesh} position={[Math.random() * 100 - 50, Math.random() * 100 - 50, Math.random() * 50 - 50]}>
      <sphereGeometry  args={[0.02, 32, 32]} />
    </mesh>
  );
}

const SpaceHolder = () => {
  const state = useThree();
  const camera = state.camera;

  let x, y;
  const velocity = 0.01;
  document.addEventListener('mousemove', (e) => {
    x = e.clientX / window.innerWidth * 2 - 1;
    y = -(e.clientY / window.innerHeight) * 2 + 1;
  });

  useFrame(() => {
    if (x && y) {
      camera.position.x += (-x - camera.position.x) * velocity;
      camera.position.y += (-y - camera.position.y) * velocity;
      camera.lookAt(0, 0, 0);
    }
  });

  return [...Array(1000)].map((_, i) => <Star key={i} />);
}



const paypalInitialOptions = {
  "client-id":
    "AYLXc661meFM9VjBgVmzKidNOShj3dYtw5woXIL1Et9RTv9i_JuB-AfrS5AJW-fSeLJrF_9GwqXkwlK0",
  "enable-funding": "paylater,venmo,card",
  "disable-funding": "",
  "data-sdk-integration-source": "integrationbuilder_sc",
};

const App = () => {
  const [message, setMessage] = useState("");

  const [delaysFor1Class, setDelaysFor1Class] = useState(0);
  const [delaysFor2Classes, setDelaysFor2Classes] = useState(0);
  const [delaysFor3Classes, setDelaysFor3Classes] = useState(0);
  const [projects, setProjects] = useState(1);

  const handleAdd = (title, value) => {
    switch (title) {
      case '1 Class':
        if (delaysFor1Class + value < 0 || delaysFor1Class + value > 1) return;
        setDelaysFor1Class(delaysFor1Class + value);
        break;
      case '2 Classes':
        if (delaysFor2Classes + value < 0 || delaysFor2Classes + value > 2) return;
        setDelaysFor2Classes(delaysFor2Classes + value);
        break;
      case '3 Classes':
        if (delaysFor3Classes + value < 0 || delaysFor3Classes + value > 3) return;
        setDelaysFor3Classes(delaysFor3Classes + value);
        break;
      case '1 Project':
        if (projects + value < 1 || projects + value > 5) return;
        setProjects(projects + value);
        break;
      default:
        break;
    }
  }

  const [_title, setTitle] = useState('');
  const [_added, setAdded] = useState(0);
  const [_price, setPrice] = useState(0);
  const priceRef = useRef(_price);

  const handlePurchase = (title, added, price) => {
    // console.log('Purchased', title, added, price);

    setTitle(title);
    setAdded(added);
    setPrice(price);
    if (added > 0) {
      if (title.includes("Project")) {
        // Multiply amount by 5 and DON'T add the price, just the amount
        priceRef.current = parseInt(added * 5);
      } else {
        // Multiply by 5 to get the total price
        priceRef.current = parseInt(price) + parseInt((added * 5));
      }
    } else {
      priceRef.current = price;
    }
    // console.log(priceRef.current)

    let main = document.querySelector('main');
    main.style.opacity = '0';
    main.style.pointerEvents = 'none';

    setTimeout(() => {
      main.style.display = 'none';

      let prompt = document.querySelector('#purchase-prompt');
      prompt.style.display = 'block';
      prompt.style.opacity = '1';
    }, 500);
  }

  const handleStartChat = (transaction) => {
    let purchasePrompt = document.querySelector('#purchase-prompt');
    purchasePrompt.style.opacity = '0';
    purchasePrompt.style.pointerEvents = 'none';
    setTimeout(() => {
      purchasePrompt.style.display = 'none';

      let chat = document.querySelector('#chat');
      chat.style.display = 'block';
      chat.style.opacity = '1';
    }, 500);
  }

  const getMessages = () => {
    let chat_history = JSON.parse(localStorage.getItem("messages")) || [];
    let messagesContainer = document.querySelector("#messages-container");
    messagesContainer.innerHTML = "";

    chat_history.forEach(message => {
      let author = message[0];
      let text = message[1];

      messagesContainer.innerHTML += `
        <div class='bg-[#00000050] px-3 py-2 rounded-xl flex flex-wrap text-wrap ${author === 'You' ? 'self-end' : 'self-start'}'>
          <div class='flex flex-col text-left'>
            <h2 class='font-bold ${author === 'You' ? '' : 'text-revolt'}'>${author}</h2>
            <p class='text-gray-200 text-wrap break-words whitespace-normal max-w-96'>${text}</p>
          </div>
        </div>
      `;
    });

    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  };
  
  const [connected, setConnected] = useState(false);
  const hasInit = useRef(false);
  useEffect(() => {
    socket.connect();
    if (hasInit.current) return;
    socket.emit('init');
    hasInit.current = true;
    socket.on('reconnect', () => {
      setConnected(true);
      let main = document.querySelector('main');
      main.style.opacity = '0';
      main.style.pointerEvents = 'none';

      setTimeout(() => {
        main.style.display = 'none';

        let chat = document.querySelector('#chat');
        chat.style.display = 'block';
        chat.style.opacity = '1';

        let enterEmailEle = document.querySelector('#enter-email');
        enterEmailEle.style.display = 'none';

        let realChatEle = document.querySelector('#real-chat');
        realChatEle.style.display = 'block';
        realChatEle.style.opacity = '1';

        startChat();
      }, 500);
    });

    socket.on('404', () => {
      setConnected(false);
      localStorage.removeItem("messages");
    });

    socket.on('leave', () => {
      setConnected(false);
      localStorage.removeItem("messages");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });

  });

  let lock = useRef(false);
  
  const startChat = () => {
    if (lock.current) return;
    lock.current = true;
    getMessages();
    socket.on("chatMessage", (data) => {
      let chat_history = JSON.parse(localStorage.getItem("messages")) || [];
      chat_history.push([data.author, data.message]);
      localStorage.setItem("messages", JSON.stringify(chat_history));
      getMessages();
    });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    let email = e.target[0].value;
    if (email === '') return;

    // console.log('Email submitted', email);

    let chatEle = document.querySelector('#chat');
    let emailEle = document.querySelector('#enter-email');
    emailEle.style.opacity = '0';
    emailEle.style.pointerEvents = 'none';

    setTimeout(() => {
      emailEle.style.display = 'none';
      chatEle.style.height = '1000px;';
      
      let realChatEle = document.querySelector('#real-chat');
      realChatEle.style.display = 'block';
      realChatEle.style.opacity = '1';

      if (connected) return;
      socket.emit('connected', { email: email, title: _title, added: _added, price: _price });

      setConnected(true);
      startChat();
    }, 500);
  }
  
  const [messageInput, setMessageInput] = useState('');
  const [lastMessageAt, setLastMessageAt] = useState(0);
  
  const handleSendMessage = () => {
    setMessageInput(messageInput.trim());
    if (messageInput === '') return;
    if (typeof messageInput !== 'string') return;
    if (messageInput.length < 0 || messageInput.length > 1000) return;

    if (Date.now() - lastMessageAt < 3000) {
      return;
    }
    setLastMessageAt(Date.now());
    socket.emit('chatMessage', { message: messageInput });
    setMessageInput('');

    let chat_history = JSON.parse(localStorage.getItem("messages")) || [];
    chat_history.push(['You', messageInput]);
    localStorage.setItem("messages", JSON.stringify(chat_history));
    getMessages();
  }

  return (
    <GlobalDelayCheck>
    <div className='font-poppins'>
      <div id="canvas-container" className="fixed z-[-1] w-full overflow-hidden shadow-[0_0_10px_0_rgba(0,0,0,0.1)]">
        <Canvas style={{
          backgroundColor: "transparent",
          height: "100vh",
          width: "100%"
        }} resize={{ scroll: false }}>
          <SpaceHolder />
        </Canvas>
      </div>
      <nav className='py-6'>
        <div className='max-w-screen-xl mx-auto flex md:justify-between justify-center items-center pb-6'>
          <div className='flex gap-6 items-end'>
            <a href='/' className='font-bold text-5xl'>REVOLT</a>
            <div className='text-gray-200 flex'>
              <span>9452 people online</span>
              <span className='inline-block h-2 w-2 bg-green-400 rounded-full animate-blink'></span>
            </div>
          </div>
          <div className='flex gap-6'>
            <a className='text-xl md:flex hidden gap-2 items-center bg-discord rounded-lg px-4 py-2 uppercase font-bold' href="https://discord.gg/edgenuity" target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faDiscord} className='text-3xl' />Join our discord
            </a>
            {/* <button className='text-4xl cursor-not-allowed'><FontAwesomeIcon icon={faBars} /></button> */}
          </div>
        </div>
      </nav>

      <header>
        <div className='mx-auto mt-16'>
          <div className='text-center flex flex-col'>
            <h1 className='uppercase md:text-7xl text-5xl font-bold mb-2'>
              Finish Your <span className='text-revolt'>Edgenuity</span> Classes Today
            </h1>
            <h2 className='md:text-xl text-lg'>
              At Revolt we <b>FIGHT</b> for your time, so you can focus on what really <b>matters</b>
            </h2>
            <a className='text-xl mt-2 self-center flex md:hidden gap-2 items-center bg-discord rounded-lg px-4 py-2 uppercase font-bold' href="https://discord.gg/edgenuity" target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faDiscord} className='text-3xl' />Join our discord
            </a>

            <div id="purchase-prompt" className="bg-white rounded-xl max-w-[600px] mx-auto mt-12 py-6 hidden opacity-0 transition duration-500">
                  <PayPalScriptProvider options={paypalInitialOptions}>
                    <PayPalButtons
                      style={{
                        shape: "pill",
                        layout: "vertical",
                        color: "gold",
                      }}
                      createOrder={async () => {
                        try {
                          const response = await fetch("/api/orders", {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            // use the "body" param to optionally pass additional order information
                            // like product ids and quantities
                            body: JSON.stringify({
                              cart: {
                                id: "revolt-class-help",
                                quantity: "1",
                                value: priceRef.current.toString(),
                              },
                            }),
                          });

                          const orderData = await response.json();

                          if (orderData.id) {
                            return orderData.id;
                          } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                              : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                          }
                        } catch (error) {
                          console.error(error);
                          setMessage(
                            `Could not initiate PayPal Checkout...${error}`,
                          );
                        }
                      }}
                      onApprove={async (data, actions) => {
                        try {
                          const response = await fetch(
                            `/api/orders/${data.orderID}/capture`,
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                            },
                          );

                          const orderData = await response.json();
                          const errorDetail = orderData?.details?.[0];
                          if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                            // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                            return actions.restart();
                          } else if (errorDetail) {
                            // (2) Other non-recoverable errors -> Show a failure message
                            throw new Error(
                              `${errorDetail.description} (${orderData.debug_id})`,
                            );
                          } else {
                            // (3) Successful transaction -> Show confirmation or thank you message
                            // Or go to another URL:  actions.redirect('thank_you.html');
                            const transaction =
                              orderData.purchase_units[0].payments.captures[0];
                            // setMessage(
                            //   `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                            // );
                            handleStartChat(transaction);
                            // console.log(
                            //   "Capture result",
                            //   orderData,
                            //   JSON.stringify(orderData, null, 2),
                            // );
                          }
                        } catch (error) {
                          console.error(error);
                          setMessage(
                            `Sorry, your transaction could not be processed...${error}`,
                          );
                        }
                      }}
                    />
                  </PayPalScriptProvider>
                  <Message content={message} />
              </div>

            <div id="chat" className='bg-[#00000050] rounded-xl max-w-[600px] mx-auto mt-12 py-6 hidden opacity-0 transition duration-500'>
              <div id="enter-email" className='transition duration-500 px-6'>

                <span>Enter your email keep in touch with us!</span>
                <form className='flex gap-4 mt-4' onSubmit={handleEmailSubmit}>
                  <input type='email' placeholder='Email' className='bg-transparent border-b border-gray-200 text-white w-full px-2 focus:outline-none' />
                  <button type="submit" className='bg-revolt px-4 py-2 rounded-lg hover:bg-blue-900 transition text-nowrap'>Proceed To Payment</button>
                </form>

              </div>

              <div id="real-chat" className='hidden transition duration-500 opacity-0'>

                {connected ? (
                  <div className='text-gray-200 flex justify-center'>
                    <span>Connected with Revolt agent</span>
                    <span className='inline-block h-2 w-2 mt-1 ml-1 bg-green-400 rounded-full animate-blink'></span>
                  </div>
                ) : (
                  <div className='text-gray-200 flex justify-center'>
                    <span>Disconnected</span>
                    <span className='inline-block h-2 w-2 mt-1 ml-1 bg-red-400 rounded-full animate-blink'></span>
                  </div>
                )}

                <div className='flex gap-4 mt-4 flex-col overflow-hidden overflow-y-auto min-h-[400px] max-h-[400px] px-6' id="messages-container"></div>

                <div className='flex gap-4 mt-4 px-6'>
                  <input type='text' placeholder='Type a message' className='bg-transparent border-b border-gray-200 text-white w-full px-2 focus:outline-none' onChange={(e) => setMessageInput(e.target.value)} value={messageInput} onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()} maxLength='1000' />
                  <button className='bg-revolt px-4 py-2 rounded-lg hover:bg-blue-900 transition text-nowrap' onClick={handleSendMessage}>Send</button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </header>

      <main className='transition duration-500'>
        <div className='max-w-screen-xl mx-auto flex flex-col gap-6 items-center mt-12 mb-40'>
          <h1 className='md:text-2xl text-xl mb-2'>Get Edgenuity Done. Now.</h1>
          <div className='flex md:gap-4 gap-2 mb-6 md:flex-row flex-col'>
            <Card title='3 Classes' price='80' features={['Fast', 'Safe', 'Cheap', 'Best Value']} type='Delays' added={delaysFor3Classes} add={handleAdd} handlePurchase={handlePurchase} />
            <Card title='1 Class' price='30' features={['Fastest', 'Safe', 'Cheap', 'Best Value', 'Recommended']} type='Delays' added={delaysFor1Class} add={handleAdd} handlePurchase={handlePurchase} />
            <Card title='2 Classes' price='55' features={['Fast', 'Safe', 'Cheap', 'Best Value']} type='Delays' added={delaysFor2Classes} add={handleAdd} handlePurchase={handlePurchase} />
          </div>
            or finish a project
            <Card title='1 Project' price='5' features={[]} type='Projects' added={projects} add={handleAdd} handlePurchase={handlePurchase} />
        </div>

        <h1 className='text-4xl mb-2 text-center'>What People Think?</h1>
        <p className='text-center text-gray-200 mb-6'>Here is what they <b>achieved fighting</b> for their time with <b>Revolt</b></p>
        <Splide aria-label="Reviews" options={{
          type   : 'loop',
          drag   : 'free',
          focus  : 'center',
          perPage: 3,
          autoScroll: {
            speed: 1,
            pauseOnHover: false,
            pauseOnFocus: false,
          },
          arrows: false,
          pagination: false,
          gap: '0',
        }} extensions={{ AutoScroll }} className='mt-18'>
          {[2, 3, 4, 5, 6].map((i) => (
            <SplideSlide key={i} className='!mr-4'>
              <img src={require(`./reviews/${i}.png`)} alt={`Review ${i}`} className='border-2 border-gray-800 rounded-2xl' />
            </SplideSlide>
          ))}
        </Splide>
      </main>

      <footer className='mt-40 md:mx-24'>
        <div className='flex flex-col relative md:px-12 border-t'>
          <h1 className='font-bold text-5xl ml-12 bg-[#0a0a0a] absolute -translate-y-1/2 px-2'>REVOLT</h1>

          <div className='flex items-center justify-between pt-8'>
            {/* <a className='text-xl flex gap-2 items-center bg-discord rounded-lg px-4 py-2 uppercase font-bold' href="https://discord.gg/edgenuity" target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faDiscord} className='text-3xl' />Join our discord
            </a>
            <div></div> */}
          </div>
          <div className='flex justify-between mb-8 md:flex-row flex-col items-center gap-2'>
            <div className=''>
              &copy; 2024 Revolt - <a href="https://lawlight.org?ref=revolt" target="_blank" rel="noreferrer" className='text-gray-700'>website made by lawlight.org</a>
            </div>
            <div className='flex items-center'>
              Find us on &nbsp;
              <a href="https://discord.gg/edgenuity" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faDiscord} className="text-3xl mx-2" />
              </a>
              <a href="https://www.tiktok.com/@revoltedgeofficial" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTiktok} className="text-3xl mx-2" />
              </a>
              <a href="https://github.com/kaidadnd/Revolt" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub} className="text-3xl mx-2" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
    </GlobalDelayCheck>
  );
}

function Message({ content }) {
  return <p style={{color: "black"}}>{content}</p>;
}

export default App;