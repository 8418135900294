import React, { createContext, useContext, useState } from "react";

const DelayStateContext = createContext();

export const GlobalDelayCheck = ({ children }) => {
  const [delayState, setDelayState] = useState(false);

  return (
    <DelayStateContext.Provider value={{ delayState, setDelayState }}>
      {children}
    </DelayStateContext.Provider>
  );
};

export const useDelayState = () => useContext(DelayStateContext);
