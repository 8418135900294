import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = ({ title, description, price, features, type, added, add, handlePurchase }) => {
    return (
        <div className={
            (title === "1 Class" ? "middleCard md:scale-110 bg-[#060606]" : "otherCard") +
            " bg-[#00000060] border-2 border-gray-800 rounded-2xl flex flex-col p-6 shadow-lg shadow-blue-900 min-w-[350px] text-center "
        }>
            <div className="flex md:flex-col items-center">
                <div className="flex-1">
                    <h2 className="font-bold text-xl">
                        {type === "Projects" && added === 1 ? `${added} Project` : type === "Projects" && added > 0 ? `${added} Projects` : title}
                        {type !== "Projects" ?
                            added === 1 ? " + 1 delay" : added > 0 ? ` + ${added} delays` : ""
                        : ""}
                    </h2>
                    <h2 className="font-bold text-5xl">
                        ${type === "Projects" ? Number(price) + (added-1) * 5 : Number(price) + added * 5}
                    </h2>
                </div>
                {type !== "Projects" && (
                    <ul className={"md:hidden flex-col md:gap-4 gap-2 flex-1 items-center flex" + (features.length > 0 ? " md:mt-6" : "")}>
                        {features.map((feature, i) => (
                            <li key={i} className="flex items-center gap-2">
                                <FontAwesomeIcon icon={faCheck} className="text-green-200 bg-green-800 rounded-full p-1 text-sm" />
                                {feature}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="flex flex-col flex-1">
                {type !== "Projects" && (
                    <ul className={"md:flex flex-col md:gap-4 gap-2 flex-1 items-center hidden" + (features.length > 0 ? " md:mt-6" : "")}>
                        {features.map((feature, i) => (
                            <li key={i} className="flex items-center gap-2">
                                <FontAwesomeIcon icon={faCheck} className="text-green-200 bg-green-800 rounded-full p-1 text-sm" />
                                {feature}
                            </li>
                        ))}
                    </ul>
                )}

                <div className="text-white font-bold border-t-2 border-gray-800 mt-6">
                    <div className="flex gap-1 items-center justify-center my-4">
                        {type}
                        <button className="bg-revolt rounded-full w-6 h-6 hover:bg-blue-900 transition flex items-center justify-center" onClick={() => add(title, -1)}>
                            -
                        </button>
                        {added}
                        <button className="bg-revolt rounded-full w-6 h-6 hover:bg-blue-900 transition flex items-center justify-center" onClick={() => add(title, 1)}>
                            +
                        </button>
                    </div>
                    <button className="bg-revolt uppercase py-2 px-4 rounded-lg w-full hover:bg-blue-900 transition" onClick={() => handlePurchase(title, added, price)}>
                        Purchase
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Card;